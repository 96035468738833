// paulirish.com/2009/log-a-lightweight-wrapper-for-consolelog/
window.log = function f(){ log.history = log.history || []; log.history.push(arguments); if(this.console) { var args = arguments, newarr; args.callee = args.callee.caller; newarr = [].slice.call(args); if (typeof console.log === 'object') log.apply.call(console.log, console, newarr); else console.log.apply(console, newarr);}};
// make it safe to use console.log always
(function(a){function b(){}for(var c="assert,count,debug,dir,dirxml,error,exception,group,groupCollapsed,groupEnd,info,log,markTimeline,profile,profileEnd,time,timeEnd,trace,warn".split(","),d;!!(d=c.pop());){a[d]=a[d]||b;}})
(function(){try{console.log();return window.console;}catch(a){return (window.console={});}}());

/* helper function for accordion */
function findActiveHeader() {

    $( $( "ol.accordion" ).find("h3") ).each(function( index ) {

        if( $(this).attr('data-acc-open') == 'is-always-active' ) {
                $(this).find("a").on('click', function() {
                    window.location.href = $(this).attr("href");
                });
        }

    });

}
/*accordion Setup */
function renderMainNavAccordion() {

        $( "ol.accordion" ).accordion({
            collapsible: true,
            active: true,
            heightStyle: "content",
            create: function( event, ui ) {
               findActiveHeader();
            }
        });
}
/* mobile nav setup */
function mobileNavToggle() {

    // bind mobile nav toggle click event
    $(".main__nav__toggle").on("click", function(e) {
        $("body").toggleClass("main__nav--open");
        $("nav.main__nav").toggleClass("open");
        $("div.main__nav__outer").toggleClass("open");
        $("div.main__nav__outer").toggleClass("main__nav--scrollable__open");
        $("a.main__nav__toggle").toggleClass("main__nav__toggle--open");
        $("div.header__content__scrolldown").toggleClass("header__content__scrolldown--hidden");
        $("div.main__nav__scroller").toggleClass("main__nav__scroller--open");
        $("header.header").toggleClass("header--open");
        e.preventDefault();
    });
}

if( $("body").hasClass("scrollable") ) {

    $(window).on('scroll', function() {

       $("div.main__nav__outer").addClass("main__nav--scrollable");
      // $("div.main__nav__outer").addClass("header__content__scrolldown");

       var height = $(window).scrollTop();

        if( height < 10 ) {
            $("div.main__nav__outer").removeClass("main__nav--scrollable");
           // $("div.main__nav__outer").removeClass("header__content__scrolldown");
       }

    });

}

function tableSorter() {
    if( $("section").hasClass("scrollable__dark__layout__datatable") ) {

        var datatable = new DataTable(document.querySelector('.rentals'), {
            pageSize: 20,
            sort: [true, true, true],
            filters: [true, true, true],
            filterText: 'Type to filter... ',
            filterSelectOptions: false,
            data: {
                url: "http://localhost/soundville/rental-json-output/json",
                type: "get",
                size: 20,
                allInOne: true,
                refresh: 3000
            }
        });

    }
}



$(document).ready(function() {
    mobileNavToggle();
    renderMainNavAccordion();
    tableSorter();

    // use the right function instead of deprecated _blank
    $("[rel=external], .external").on("click", function(e) {
        window.open($(this).attr("href"), "_blank");
        e.preventDefault();
    });


    var offset = 220;
    var duration = 500;

    $(window).scroll(function() {
        if ($(this).scrollTop() > offset) {
            $('div.footer__scroll--up').fadeIn(duration);
        } else {
            $('div.footer__scroll--up').fadeOut(duration);
        }
    });

    $('div.footer__scroll--up').click(function(event) {
        event.preventDefault();
        $('html, body').animate({scrollTop: 0}, duration);
        return false;
    });

});